import {Form, Input, Modal, Select} from 'antd';
import {t}                          from 'foundations/i18n/i18n';
import {useApi}                     from 'foundations/api/use-api';
import {useForm}                    from 'foundations/form/use-form';

export function CreateContactMethodModal({
    open,
    toggle,
    providerId
}: {
    open: boolean,
    toggle: () => void,
    providerId: string
}) {
    const form      = useForm({
        type:        'email',
        value:       undefined,
        provider_id: providerId
    });
    const createApi = useApi('post', '/contact-methods/create', form.data);
    const options   = [
        {
            label: t('Email'),
            value: 'email'
        },
        {
            label: t('SMS'),
            value: 'sms'
        }
    ];

    return (
        <Modal
            open={open}
            onCancel={toggle}
            title={t('Create Contact Method')}
            onOk={createApi.submit}
            okButtonProps={{children: t('Create'), onClick: createApi.submit, loading: createApi.status.working}}>
            <Form layout='vertical'>
                <Form.Item label={t('Delivery Type')}>
                    <Select {...form.register('type')} options={options}/>;
                </Form.Item>
                <Form.Item label={form.data.type === 'email' ? t('Email') : t('Phone Number')}>
                    <Input {...form.register('value')}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}