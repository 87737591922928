import {Button}      from 'antd';
import {AuthLayout}  from 'features/auth/auth-layout';
import {useAuth0}    from '@auth0/auth0-react';

export function Login() {
    const {loginWithRedirect, isLoading} = useAuth0();

    return (
        <AuthLayout>
            <div style={{marginBottom: 24}}>
                <Button block type={'primary'} onClick={() => loginWithRedirect()} loading={isLoading}>{'Sign In'}</Button>
            </div>
        </AuthLayout>
    );
}