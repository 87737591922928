import {Layout, Menu, MenuProps, Typography} from 'antd';
import React                                 from 'react';
import {t}                                   from 'foundations/i18n/i18n';
import {useLocation, useNavigate}            from 'react-router';
import logoLight                             from 'foundations/logos/logo-light.png';

export function DefaultLayout({
    children,
    title,
    actions
}: {
    children: React.ReactNode,
    actions?: React.ReactNode
    title?: string,
}) {

    const navigate                 = useNavigate();
    const location                 = useLocation();
    const go: MenuProps['onClick'] = (event) => navigate(event.key);

    const items = [
        {
            key:   '/orders',
            label: t('Orders')
        },
        {
            key:   '/accounts',
            label: t('Accounts')
        },
        {
            key:   '/permissions',
            label: t('Permissions')
        },
        {
            key:   '/verticals',
            label: t('Verticals')
        },
        {
            key:   '/categories',
            label: t('Categories')
        },
        {
            key:   '/providers',
            label: t('Providers')
        },
        {
            key:   '/availability-zones',
            label: t('Availability Zones')
        },
        {
            key:   '/products',
            label: t('Products')
        },
        {
            key:   '/banners',
            label: t('Banners')
        },{
            key:   '/coupons',
            label: t('Coupons')
        },
        {
            key:   '/fields',
            label: t('Fields')
        }
    ];

    return (
        <Layout>
            <Layout.Header style={{display: 'flex', alignItems: 'center', gap: 8, paddingLeft: 32}}>
                <img src={logoLight} height={30} width={30} style={{objectFit: 'cover', objectPosition: '0'}}/>
                <span style={{fontWeight: '900', color: 'white', fontSize: '22px'}}>Backhouse</span>
            </Layout.Header>
            <Layout.Content>
                <Layout style={{minHeight: '100vh'}} hasSider>
                    <Layout.Sider style={{background: '#f6f6f6'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh', boxSizing: 'border-box'}}>
                            <Menu onClick={go} theme='light' style={{background: '#f6f6f6', border: 'none'}} defaultSelectedKeys={[location.pathname]} mode='inline' items={items}/>
                        </div>
                    </Layout.Sider>
                    <Layout style={{background: 'white', padding: 40}}>
                        <header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div>
                                <Typography.Title level={1}>{title}</Typography.Title>
                            </div>
                            <div>
                                {actions}
                            </div>
                        </header>
                        <Layout.Content>
                            {children}
                        </Layout.Content>
                    </Layout>
                </Layout>
            </Layout.Content>
        </Layout>
    );
}